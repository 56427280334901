import {
  Injectable
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Storage
} from '@ionic/storage';
import {
  ToastController,
  Platform
} from '@ionic/angular';
import {
  BehaviorSubject
} from 'rxjs';

import {
  tap, map
} from 'rxjs/operators';

import {
  Http,
  Headers,
  RequestOptions,
  Response
} from '@angular/http';

let apiUrl = 'https://dash.appliam.com/api/guestlist/';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authState = new BehaviorSubject(false);
  token: any;
  user: any;
  admin: any;
  rep: any;

  constructor(
    private router: Router,
    private storage: Storage,
    private platform: Platform,
    public http: Http,
    public toastController: ToastController
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }

  ifLoggedIn() {
    this.storage.get('USER_TOKEN').then((response) => {
      if (response) {
        this.token = response;
        this.getUser().pipe(map(res => res.json())).subscribe(res => {
          this.user = res.user;
          this.admin = res.admin;
          this.rep = res.rep;
          this.authState.next(true);
        });
      }
    });
  }

  login(username: string, password: string, error) {
    let credentials = {
      username: username,
      password: password,
    };

    return this.http.post(apiUrl + 'auth/token', credentials)
      .subscribe(res => {
        this.storage.set('USER_TOKEN', res.json()).then((response) => {
          this.token = res.json();
          this.getUser().pipe(map(res => res.json())).subscribe(res => {
            this.user = res.user;
            this.admin = res.admin;
            this.rep = res.rep;
            if (this.admin) {
              console.log('[AUTH SERVICE] => USER IS ADMIN REDIRECTING');
              this.router.navigate(['dashboard']);
            }
            if (this.rep) {
              console.log('[AUTH SERVICE] => USER IS REP REDIRECTING');
              this.router.navigate(['reps']);
            }
          });
          this.authState.next(true);
        });
      }, (err) => {
        error();
        console.error('USER AUTH ERROR');
      });

  }

  getUser() {
    const headers = new Headers({
      'Authorization': this.token["token_type"] + " " + this.token["access_token"]
    });
    return this.http.get(apiUrl + 'user', {
        headers: headers
      });
  }

  logout() {
    this.storage.remove('USER_TOKEN').then(() => {
      this.router.navigate(['login']);
      this.authState.next(false);
    });
  }

  isAuthenticated() {
    return this.authState.value;
  }
}
import {
  NgModule
} from '@angular/core';
import {
  PreloadAllModules,
  RouterModule,
  Routes
} from '@angular/router';
import {
  AuthGuardService
} from './services/auth-guard.service';

const routes: Routes = [{
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './login/login.module#LoginPageModule'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dash-tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'reps',
    loadChildren: () => import('./rep-tabs/rep-tabs.module').then(m => m.RepTabsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'guest',
    loadChildren: './guest/guest.module#GuestPageModule'
  },
  {
    path: 'guest/event/:id',
    loadChildren: './guest-event-detail/guest-event-detail.module#GuestEventDetailPageModule'
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
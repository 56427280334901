import {
  Injectable
} from '@angular/core';
import {
  Http,
} from '@angular/http';
import {
  map
} from 'rxjs/operators';

import * as moment from 'moment';
import {
  Observable
} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppliamService {

  apiURL: string = 'https://dash.appliam.com/api/app/';
  appID: number = 72;
  app: any = {};
  lastFetched: any = null;
  fetchInterval: number = 3600000; //60 * 1000 = 60 seconds = 1 minute * 60 = 1 hour

  constructor(private http: Http) {}

  getApp() {
    if (this.needFetch()) {
      this.lastFetched = moment().valueOf();
      return this.getAppLatest().pipe(
        map(res => res.json()),
        map(res => this.app = res)
      );
    } else {
      console.log('Returning app cache as observable...');
      return new Observable(subscriber => {
        subscriber.next(this.app);
        subscriber.complete();
      });
    }
  }

  getAppLatest() {
    return this.http.get(`${this.apiURL}` + `${this.appID}`);
  }

  needFetch(): boolean {
    if (this.lastFetched == null) {
      console.log('App has not been fetched yet, fetching...');
      return true;
    }
    if ((moment().valueOf() - this.lastFetched) >= this.fetchInterval) {
      console.log('App has not been fetched within the fetch interval, fetching...');
      return true;
    }
    console.log('App does not need a fetch');
    return false;
  }

}
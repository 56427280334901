import {
  Component
} from '@angular/core';
import {
  Platform,
  ToastController
} from '@ionic/angular';
import {
  Plugins,
  StatusBarStyle,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';
import {
  Router
} from '@angular/router';
import {
  AuthenticationService
} from './services/authentication.service';


const {
  PushNotifications
} = Plugins;

const {
  SplashScreen
} = Plugins;

const {
  StatusBar
} = Plugins;


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router,
    public platform: Platform,
    public authenticationService: AuthenticationService,
    public toastCtrl: ToastController,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      StatusBar.setStyle({
        //THE STATUS BAR IS DARK - SO LIGHT TEXT
        style: StatusBarStyle.Dark
      });

      SplashScreen.hide();

      console.log('Initializing Login');

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On succcess, we should be able to receive notifications
      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          console.log('Push registration success, token: ' + token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotification) => {
          console.log('Push received: ' + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));
        }
      );

      this.authenticationService.authState.subscribe(state => {
        if (state) {
          if (this.authenticationService.admin == 1) {
            console.log('[INIT APP] USER IS ADMIN');
            this.router.navigateByUrl('/dashboard');
          }
          if (this.authenticationService.rep == 1) {
            console.log('[INIT APP] USER IS REP');
            this.router.navigateByUrl('/reps');
          }
        } else {
          this.router.navigateByUrl('/login');
        }
      });

    });
  }

}
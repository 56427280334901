import { Injectable } from '@angular/core';
import { Http, Headers } from '@angular/http';
import { AppliamService } from './appliam.service';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})

export class RepService {

  apiURL = 'https://dash.appliam.com/api';

  constructor(private http: Http, private appliam: AppliamService, private auth: AuthenticationService) { }

  getReps() {
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.get(`${this.apiURL}/guestlist/reps/${this.appliam.appID}`, {
      headers: headers
    });
  }

  getRep(id) {
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.get(`${this.apiURL}/guestlist/rep/${id}`, {
      headers: headers
    });
  }

  getRepProfile() {
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.get(`${this.apiURL}/guestlist/rep/profile`, {
      headers: headers
    });
  }

  newRep(data) {
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.post(`${this.apiURL}/guestlist/rep/new/${this.appliam.appID}`, data, {
      headers: headers
    });
  }

  updateProfile(data){
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.post(`${this.apiURL}/guestlist/rep/update`, data, {
      headers: headers
    });
  }

  changePassword(data){
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.post(`${this.apiURL}/guestlist/rep/password`, data, {
      headers: headers
    });
  }

  deleteRep(repID: any) {
    const headers = new Headers({
      'Authorization': this.auth.token["token_type"] + " " + this.auth.token["access_token"]
    });
    return this.http.delete(`${this.apiURL}/guestlist/rep/${repID}`, {
      headers: headers
    });
  }


}
